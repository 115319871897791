var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-container" }, [
    _vm._m(0),
    _vm._m(1),
    _c(
      "table",
      _vm._l(this.$store.state.topWins, function (win, index) {
        return _c("tr", { key: index }, [
          _c(
            "td",
            { staticStyle: { "text-align": "left", "padding-left": "40px" } },
            [
              _c("span", { staticClass: "circle" }),
              _c("span", { staticClass: "location" }, [
                _vm._v(_vm._s(win.shopName)),
              ]),
            ]
          ),
          _c("td", { staticClass: "date-time" }, [
            _c(
              "p",
              { staticStyle: { color: "lightgray", "font-weight": "lighter" } },
              [_vm._v(" " + _vm._s(_vm.formatDateDD(win.ticketDate)) + " ")]
            ),
            _c("p", [_vm._v(_vm._s(_vm.formatDateHH(win.ticketDate)))]),
          ]),
          _c("td", [
            _c("p", { staticClass: "td-title" }, [_vm._v("Pariu LEI")]),
            _c("p", { staticClass: "amount" }, [
              _vm._v(_vm._s(win.playedAmount)),
            ]),
          ]),
          _c("td", { staticClass: "amount" }, [
            _c("p", { staticClass: "td-title" }, [_vm._v("Câștig LEI")]),
            _c("p", { staticClass: "amount-won" }, [
              _vm._v(_vm._s(win.winAmount)),
            ]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "title",
        staticStyle: {
          width: "50%",
          display: "inline-block",
          "text-align": "right",
          "box-sizing": "border-box",
          "padding-right": "20px",
        },
      },
      [
        _c("span", { staticStyle: { margin: "0", padding: "0" } }, [
          _vm._v("TOP BILETE "),
          _c("br"),
          _vm._v(" CÂȘTIGĂTOARE"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "title",
        staticStyle: {
          width: "50%",
          display: "inline-block",
          "text-align": "left",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              margin: "0",
              "box-sizing": "border-box",
              "padding-left": "20px",
              "font-size": "80px",
              width: "50%",
            },
          },
          [_vm._v("7 Zile")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }