let settings = {
  development: {
    //"https://tbet.oddin-platform.com/virtualsweb"
    rocketServer: "https://lgames.ibetplatform.com",
    //"http://51.68.181.111:44395"
  },
  production: {
    rocketServer: "https://lgames.ibetplatform.com",
  },
  testingMode: false,
};

export default settings;
