/* import Vue from "vue"; */
import axios from "axios";
import settings from "../settings/generalSettings.js";
/* import { store } from "../store/index.js";
import bus from "./bus"; */
class ApiService {
  constructor() {
    this.TerminalApi = axios.create({
      baseURL: settings.production.rocketServer + "/virtualsapi/api/",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getSettings() {
    return this.TerminalApi.get("utils/payTable/Rocket");
  }

  getTopWins(amount, days) {
    return this.TerminalApi.get(`rocket/topWins/${amount}/${days}`);
  }
}

export { ApiService };
