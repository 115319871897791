var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "disconnect-modal" } }, [
    _c("div", { staticClass: "disconnect-mask" }, [
      _c(
        "div",
        {
          staticClass: "disconnect-body",
          staticStyle: {
            color: "white",
            "text-align": "center",
            margin: "auto",
            width: "1920px",
            "font-size": "80px",
          },
        },
        [
          _c("p", { staticStyle: { "margin-top": "20%" } }, [
            _vm._v("CONECTARE ESUATA!"),
          ]),
          _c("p", [_vm._v("SE INCEARCA RECONECTAREA...")]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }