var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.$store.state.connectedToHub
    ? _c("div", { staticClass: "draw-content" }, [
        this.$store.state.showDisconnect
          ? _c(
              "div",
              {
                key: this.$store.state.showDisconnect,
                staticClass: "disconnect-modal",
              },
              [_c("disconnect")],
              1
            )
          : _vm._e(),
        this.$store.state.showMaintenance
          ? _c(
              "div",
              {
                key: this.$store.state.showMaintenance,
                staticClass: "maintenance-modal",
              },
              [_c("maintenance")],
              1
            )
          : _vm._e(),
        this.$store.state.showJackpot
          ? _c(
              "div",
              {
                key: this.$store.state.showJackpot,
                staticClass: "jackpot-modal",
              },
              [_c("jackpot")],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: this.$store.state.isDrawVisible,
                expression: "this.$store.state.isDrawVisible",
              },
            ],
            staticClass: "box-content",
          },
          [
            _c(
              "div",
              { staticClass: "scroll-restyle ng-tns-0-2" },
              [
                _c(
                  "app-root",
                  { attrs: { "_nghost-kec-c90": "", "ng-version": "13.2.6" } },
                  [
                    _c("app-game", [
                      _c(
                        "div",
                        {
                          staticClass: "mw hide-chat-bar",
                          attrs: { "_content-1": "" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mc", attrs: { "_content-1": "" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "w-100 h-100",
                                  attrs: { "_content-1": "" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "gc",
                                      attrs: { "_content-1": "" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "gp",
                                          attrs: { "_content-1": "" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "sb",
                                              attrs: { "_content-1": "" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "pbw",
                                                  attrs: { "_content-1": "" },
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "dc",
                                                    attrs: { "_content-1": "" },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "sc",
                                                      attrs: {
                                                        "_content-1": "",
                                                      },
                                                    },
                                                    [
                                                      _c("app-play-board", {
                                                        attrs: {
                                                          id: "crash-game",
                                                          drawVisible:
                                                            _vm.isDrawVisible,
                                                          startGame:
                                                            _vm.startGame,
                                                          stopGame:
                                                            _vm.stopGame,
                                                          currentRoundDate:
                                                            _vm.currentRoundDate,
                                                          "_content-1": "",
                                                          "_nghost-kec-c65": "",
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "multiplier-overlap",
                                                          class:
                                                            this.multiplier_cls,
                                                        },
                                                        [
                                                          _vm.isDrawVisible
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "multiplier-round",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "round-text",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          "RUNDA: " +
                                                                            _vm.currentRound
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.isDrawVisible
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    "x" +
                                                                      _vm.multiplier.toFixed(
                                                                        2
                                                                      )
                                                                  )
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.countdownSeconds <= 5 && _vm.countdownSeconds >= 1
              ? _c("div", { staticClass: "countdown-modal" }, [
                  _c("div", { attrs: { id: "inner-circle" } }, [
                    _c("div", [_vm._v(_vm._s(_vm.countdownSeconds))]),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !this.$store.state.isDrawVisible,
                expression: "!this.$store.state.isDrawVisible",
              },
            ],
            staticClass: "balls-results-content",
          },
          [
            this.$store.state.historyResultsRounds.length > 0
              ? _c("result-round", {
                  attrs: {
                    historyResultsRounds: _vm.historyResultsRounds,
                    currentRound: _vm.currentRound,
                    countdownSeconds: _vm.countdownSeconds,
                  },
                })
              : _c("div", [_c("waiting")], 1),
          ],
          1
        ),
      ])
    : _c(
        "div",
        {
          staticStyle: {
            color: "white",
            "text-align": "center",
            margin: "auto auto",
            width: "100%",
            "margin-top": "20%",
            "font-size": "80px",
          },
        },
        [
          _c("p", [_vm._v("Se incarca jocul!")]),
          _c("p", [_vm._v("Va rugam asteptati!")]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }