import { render, staticRenderFns } from "./TopWins.vue?vue&type=template&id=549b8c4b"
import script from "./TopWins.vue?vue&type=script&lang=js"
export * from "./TopWins.vue?vue&type=script&lang=js"
import style0 from "./TopWins.vue?vue&type=style&index=0&id=549b8c4b&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('549b8c4b')) {
      api.createRecord('549b8c4b', component.options)
    } else {
      api.reload('549b8c4b', component.options)
    }
    module.hot.accept("./TopWins.vue?vue&type=template&id=549b8c4b", function () {
      api.rerender('549b8c4b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/table/TopWins.vue"
export default component.exports