var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "jackpot-modal" } }, [
    _c("div", { staticClass: "jackpot-mask" }, [
      _c("div", { staticClass: "jackpot-body" }, [
        _c("div", { staticClass: "jackpot-info" }, [
          _c("div", { staticClass: "round" }, [
            _vm._v("Runda " + _vm._s(this.$store.state.jackpotRound)),
          ]),
          _c("div", { staticClass: "code" }, [
            _vm._v("Cod " + _vm._s(this.$store.state.jackpotCode)),
          ]),
          _c("div", { staticClass: "amount" }, [
            _vm._v(_vm._s(this.$store.state.jackpotValue) + " LEI"),
          ]),
          _c("div", { staticClass: "shop" }, [
            _vm._v(_vm._s(this.$store.state.jackpotShop)),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }