var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box-content" }, [
    _c("div", { staticClass: "box-center" }, [
      this.screenIndex <= 1
        ? _c("div", { staticClass: "center-screen" }, [_c("TopWins")], 1)
        : _vm._e(),
      this.screenIndex > 1
        ? _c("div", { staticClass: "center-screen" }, [_vm._m(0)])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "last-round-result" }, [
      _c("p", { staticClass: "text-result" }, [_vm._v("CUM CÂȘTIGI?")]),
      _c(
        "p",
        {
          staticClass: "round-text",
          staticStyle: { width: "50%", "text-align": "center", margin: "auto" },
        },
        [
          _vm._v(
            " Ai cota pariată pe bilet mai mică sau egală decât cota afișată pe ecran? "
          ),
        ]
      ),
      _c(
        "p",
        {
          staticClass: "round-text",
          staticStyle: { width: "50%", "text-align": "center", margin: "auto" },
        },
        [_vm._v(" Felicitări! Ai câștigat! ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }