var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box-content" }, [
    _c("div", { staticClass: "current-round" }, [
      _c("div", { staticClass: "round-number" }, [
        _vm._v(_vm._s(this.$store.state.headerText)),
      ]),
      _c("div", { staticClass: "logo" }),
      _vm.countdownSeconds > 5
        ? _c("div", { staticClass: "countdown" }, [
            _vm._v(
              " TIMP RĂMAS: " +
                _vm._s(_vm.formatCountdown(_vm.countdownSeconds)) +
                " "
            ),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "jackpots" }, [
      this.$store.state.globalJackpot > 0
        ? _c("div", { staticClass: "globaljackpot" }, [
            _c("p", { staticClass: "jackpot-label" }, [
              _vm._v("JACKPOT GLOBAL"),
            ]),
            _c("p", { staticClass: "jackpot-value" }, [
              _vm._v(_vm._s(this.$store.state.globalJackpot) + " RON"),
            ]),
          ])
        : _vm._e(),
      this.$store.state.localJackpot > 0
        ? _c("div", { staticClass: "localjackpot" }, [
            _c("p", { staticClass: "jackpot-label" }, [
              _vm._v("JACKPOT LOCAL"),
            ]),
            _c("p", { staticClass: "jackpot-value" }, [
              _vm._v(_vm._s(this.$store.state.localJackpot) + " RON"),
            ]),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "box-center" }, [
      _vm.screenShowcase == "last-round"
        ? _c("div", { staticClass: "center-screen" }, [
            _c("div", { staticClass: "last-round-result" }, [
              _c("p", { staticClass: "text-result" }, [
                _vm._v("ULTIMUL REZULTAT"),
              ]),
              _c("p", [_vm._v(_vm._s(_vm.historyResultsRounds[0]["result"]))]),
              _c("p", { staticClass: "round-text" }, [
                _vm._v(
                  " RUNDA " +
                    _vm._s(_vm.historyResultsRounds[0]["roundNo"]) +
                    " "
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.screenShowcase == "odds-frequency" || _vm.screenShowcase == "help1"
        ? _c("div", { staticClass: "center-screen" }, [
            _c("div", { staticClass: "last-round-result-2" }, [
              _c(
                "div",
                { staticClass: "last-round-result" },
                [_c("TopWins")],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm.screenShowcase == "help2"
        ? _c("div", { staticClass: "center-screen" }, [_vm._m(0)])
        : _vm._e(),
      _c("div", { staticClass: "history-results" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "history-grid" },
          _vm._l(_vm.historyResultsRounds, function (result) {
            return _c(
              "div",
              { key: result.roundNo, staticClass: "history-result" },
              [
                _c(
                  "div",
                  { class: _vm.getMultiplierColorClass(result.result) },
                  [_vm._v(" " + _vm._s(result.result) + "x ")]
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "last-round-result" }, [
      _c("p", { staticClass: "text-result" }, [_vm._v("CUM CÂȘTIGI?")]),
      _c(
        "p",
        {
          staticClass: "round-text",
          staticStyle: { width: "60%", "text-align": "center", margin: "auto" },
        },
        [
          _vm._v(
            " Ai cota pariată pe bilet mai mică sau egală decât cota afișată pe ecran? "
          ),
        ]
      ),
      _c(
        "p",
        {
          staticClass: "round-text",
          staticStyle: { width: "50%", "text-align": "center", margin: "auto" },
        },
        [_vm._v(" Felicitări! Ai câștigat! ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          "font-size": "35px",
          "margin-left": "auto",
          "margin-right": "auto",
        },
      },
      [_vm._v(" Istoric "), _c("br"), _vm._v(" runde ")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }