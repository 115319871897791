<template>
  <transition name="maintenance-modal">
    <div class="maintenance-mask">
      <div class="maintenance-body"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Maintenance",
};
</script>
<style scoped>
.maintenance-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: opacity 0.3s ease;
  background-color: rgba(0, 0, 0, 0.5);
}
.maintenance-body {
  position: absolute;
  top: 0;
  left: 0;
  height: 1080px;
  width: 1920px;
  background-image: url("../assets/images/maintenance-background.png");
  background-size: 100% 100%;
  z-index: 9998;
}
</style>
