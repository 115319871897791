import Vue from "vue";
import "url-search-params-polyfill";
import App from "./App.vue";
import VueLogger from "vuejs-logger";
import settings from "./settings/generalSettings.js";
import rocketHub from "./services/RocketHub";
import { store } from "./store/index.js";

Vue.config.productionTip = false;
//const isProduction = process.env.NODE_ENV === "production";
const loggerOptions = {
  isEnabled: true,
  logLevel: "error",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

Vue.use(VueLogger, loggerOptions);

let signalRServerpath =
  settings.production.rocketServer + "/virtualsweb/lib/signalr/signalr.js";
var signalRSricpt = document.createElement("script");
signalRSricpt.setAttribute("src", signalRServerpath);
document.head.appendChild(signalRSricpt);
Vue.config.ignoredElements = ["app-root", "app-game", "app-play-board"];
new Vue({
  render: (h) => h(App),
  store,
  mounted() {
    let shopId = new URLSearchParams(window.location.search).get("shopId");
    store.dispatch("setShopId", shopId);
    rocketHub().start();
  },
}).$mount("#app");
